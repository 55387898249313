import $ from 'jquery'
import {type App, type ComponentPublicInstance, createApp} from 'vue'
import type { Component, ComponentOptions } from 'vue'
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import '@chenfengyuan/datepicker'
import 'timepicker/jquery.timepicker'
import type {SortableEvent} from "sortablejs";

interface CompPropHash {
  [index: string]: any;
}

export interface MountedApp {
  app?: App<Element>;
}

export function isNonEmptyString(value?: null | string): value is string {
  return typeof value === 'string' && value.length > 0 && /^\s*$/.test(value) == false
}


// https://css-tricks.com/creating-vue-js-component-instances-programmatically/
// https://forum.vuejs.org/t/add-component-to-dom-programatically/7308
// fixme: should transform complex-attribute-name to 'complexAttributeName'
export function tsInitVue(
  component: any,
  el: Element,
  props?: CompPropHash,
  mountedApp?: MountedApp
  ): ComponentPublicInstance {

  const app = createApp(component, props || {})
  app.use(I18NextVue, {i18next});
  if (mountedApp) {
    mountedApp.app = app
  }
  return app.mount(el)
}

export function tsInitVueGrid(
  component: { new(options?: ComponentOptions<T>): T } & Component,
  el: Element,
  props?: CompPropHash,
): ComponentPublicInstance {

  const propsData: CompPropHash = {}
  if (props) {
    Object.assign(propsData, props)
  }

  if (el instanceof HTMLElement) {
    if (isNonEmptyString(el.dataset.url)) {
      propsData.listUrl = el.dataset.url
    } else if (isNonEmptyString(el.dataset.listUrl)) {
      propsData.listUrl = el.dataset.listUrl
    }

    if (el.dataset.addCheckmarks == 'true') {
      propsData.addCheckmarks = true
    }

    if (el.dataset.newItemActionView == 'true') {
      propsData.newItemActionView = true
    }

    if (el.dataset.serializeToJsona == 'true') {
      propsData.serializeToJsona = true
    }

    if (el.dataset.newHeaderActionEmit == 'true') {
      propsData.newHeaderActionEmit = true
    }
  }

  return tsInitVue(component, el, propsData)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isEmptyOrNotString(value: any): boolean {
  return !isNonEmptyString(value)
}

export function isBlank(value?: null | string | number | any[]): boolean {
  if (value == null) {
    return true
  }
  if (Array.isArray(value)) {
    return value.length == 0
  }

  if (typeof value === 'string' && (value.length === 0 || /^\s*$/.test(value))) {
    return true
  }

  if (typeof value === 'number' && isNaN(value)) {
    return true
  }

  return !(value || false)
}

export function isPresent(value?: null | string | number | any[]): boolean {
  return !isBlank(value)
}

export function initializeDatePicker(dpEl: HTMLElement): void {
  const dateInputEl = dpEl.querySelector(':scope input[ref="date"], :scope input[_ref="date"]')
  if (dateInputEl instanceof HTMLInputElement) {
    const dateTogglerEl = dpEl.querySelector(':scope [ref="date-toggler"], :scope [_ref="date-toggler"]')
    $(dateInputEl).datepicker({
      trigger: dateTogglerEl,
      autoHide: true,
      format: 'dd/mm/yyyy',
      zIndex: 2048, // otherwise will be hidden behind a modal
      weekStart: 1
    })

    if (dpEl.dataset.dateSyncRef) {
      $(dateInputEl).on("show.datepicker", function() {
        const fromDpInputEl = document.querySelector(`[data-date-ref="${dpEl.dataset.dateSyncRef}"] input[ref="date"]`)
        if (fromDpInputEl instanceof HTMLInputElement) {
          const initialDateStr = fromDpInputEl.value
          if (!isNonEmptyString(dateInputEl.value)) {
            const dp = $(dateInputEl).data('datepicker')
            if (dp) {
              const initialDate = dp.parseDate(isNonEmptyString(initialDateStr) ? initialDateStr : new Date())
              dp.viewDate = new Date(initialDate);
              dp.initialDate = new Date(initialDate);
            }
          }
        }
      })
    }
  }

  const timeInputEl = dpEl.querySelector(':scope input[ref="time"], :scope input[_ref="time"]')
  if (timeInputEl instanceof HTMLInputElement) {
    $(timeInputEl).timepicker({
      timeFormat: 'H:i',
      step: 30,
      scrollDefault: '09:00',
      showOn: [],
      showOnFocus: false,
      stopScrollPropagation: true,
      wrapperHTML: '<div><div class="tp-wrapper" data-container="timepicker-list"></div></div>',
      offsetBottom: 10,
      offsetTop: -10
    })

    const timeTogglerEl = dpEl.querySelector(':scope [ref="time-toggler"], :scope [_ref="time-toggler"]')
    if (timeTogglerEl instanceof HTMLElement) {
      timeTogglerEl.addEventListener('click', function (e) {
        e.preventDefault()
        const $tpEl = $(timeInputEl)
        if ($tpEl.timepicker('isVisible')) {
          $tpEl.timepicker('hide')
        } else {
          $tpEl.timepicker('show')
        }
      })
    }
  }
}

export function getValueStrNullable(value: string | null, defaultValue?: string): string | null {
  if (isNonEmptyString(value)) {
    return value
  }
  return defaultValue || null
}

export function getValueStr(value: string | null, defaultValue: string): string {
  if (isNonEmptyString(value)) {
    return value
  }
  return defaultValue
}

export function getValueNum(value: number | null, defaultValue?: number | null): number | null {
  if (value && !isNaN(value)) {
    return value
  }
  return defaultValue || null
}

// export function getValue<T>(value: T | null, defaultValue: T | null): T | null {
//   if (isPresent(value)) {
//     return value
//   }

//   return defaultValue || null
// }

export function sortSortableList(list: any[], event?: SortableEvent) {
  if (event) {
    list.splice(event.newIndex, 0, list.splice(event.oldIndex, 1)[0])
  }
}
